[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4A4C51;
  --login-bg2: #2E1B3F;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #D9A527;
  --saas-main-color: #693A0F;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #D9A527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #EB892E;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #FFDD8C;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #FFE7AC;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6C3500;
  --login-bg2: #201102;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__5mCEE3Rz {
  left: 16px;
  right: 16px;
  width: calc(100vw - 32px);
  margin-left: calc((1200px - 100vw + 36px) / 2);
}
.__5mCEE3Rz .__48Z9KFQa {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(592px, auto) 340px;
}
.__5mCEE3Rz .ant-tabs-nav-operations {
  display: none !important;
}
.__5mCEE3Rz .ant-radio-button-wrapper {
  border: none;
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  width: 0;
}
.__5mCEE3Rz .ant-input-disabled {
  background: transparent;
}
.__5mCEE3Rz .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 24px;
}
.__5mCEE3Rz .ant-tabs-tab {
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
}
.__5mCEE3Rz .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.__5mCEE3Rz .ant-radio-group {
  width: 102%;
  padding: 0;
}
.__5mCEE3Rz .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.__5mCEE3Rz .ant-radio-button-wrapper {
  width: 49%;
  text-align: center;
}
.__5mCEE3Rz .ant-radio-button-wrapper:first-child {
  border-left: none !important;
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 4px;
  color: var(--white);
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.__5mCEE3Rz .ant-form-item-control-input {
  height: 40px;
  border-radius: 4px;
}
.__5mCEE3Rz .ant-input-group .ant-input {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  text-align: right;
}
.__5mCEE3Rz .ant-input-group-addon {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.__5mCEE3Rz .ant-input-group-addon:first-child {
  padding-right: 0;
}
.__5mCEE3Rz .ant-input-group-addon:last-child {
  padding-left: 0;
}
.__5mCEE3Rz .ant-form-item {
  margin-bottom: 21px;
}
.__5mCEE3Rz .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  padding: 0 5px;
}
.__5mCEE3Rz .ant-select-single .ant-select-selector .ant-select-selection-item,
.__5mCEE3Rz .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.K4snNRWb {
  background: var(--module-bg);
  min-height: 550px;
  max-height: 85vh;
  padding: 24px 24px 20px;
}
.K4snNRWb .lAMoeE34 {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.K4snNRWb .lAMoeE34 .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 5px;
  top: 3px;
}
.K4snNRWb .lAMoeE34 .NKVwujBt {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy {
  display: flex;
  align-items: center;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .u2Tmj4xd {
  margin: 0 10px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  cursor: pointer;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .OeRuu4MT {
  color: var(--white-op3);
  cursor: pointer;
  font-size: 12px;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.K4snNRWb .TYeBkIne {
  width: 100%;
  min-height: 390px;
  overflow: hidden;
  height: 80%;
  position: relative;
}
.K4snNRWb .zzmwmHm1 {
  min-height: 390px;
  overflow: hidden;
  max-height: 80vh;
  height: 80%;
}
.K4snNRWb .WhMStD5q {
  color: var(--white);
}
.K4snNRWb .pH2SMw_A {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
}
.K4snNRWb ._iVQIvFP,
.K4snNRWb .oxMUarOP {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  flex-shrink: 0;
}
.K4snNRWb .oxMUarOP {
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 14px;
}
.K4snNRWb .oxMUarOP .ant-btn {
  min-width: 40px;
  height: 24px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  border: none;
  margin-right: 6px;
  border-radius: 4px;
  padding: 0;
}
.K4snNRWb .oxMUarOP .ant-btn:last-child {
  margin-right: 0;
}
.K4snNRWb .oxMUarOP ._shifiAb {
  padding: 0 5px;
  background: var(--main-bg-color);
  color: var(--main-color) !important;
}
.K4snNRWb .boUAV4UO {
  cursor: pointer;
  color: var(--main-color);
}
.EI_YoR1Z {
  min-height: 550px;
  max-height: 85vh;
  background: var(--module-bg);
  padding: 21px 15px;
  overflow-y: auto;
}
.v44tVgN9 {
  height: 50px;
  background: var(--module-bg);
  padding: 21px 15px;
}
.nTIR0UDp {
  color: var(--main-color);
  cursor: pointer;
}
.__55T1PVOk {
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}
.__55T1PVOk:hover {
  color: var(--white) !important;
  transition: none;
}
.SVfhDHkj {
  background: var(--module-bg);
  padding: 24px;
  min-height: 550px;
  max-height: 85vh;
  overflow-y: auto;
}
.SVfhDHkj .bzLvE_lp {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 48px;
}
.SVfhDHkj .D14LRIMN {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.SVfhDHkj ._BDMmsdD {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.SVfhDHkj .KXBoJW1Q {
  width: 54px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}
.SVfhDHkj .KXBoJW1Q:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.SVfhDHkj .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.SVfhDHkj .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.SVfhDHkj .ant-form-item-control-input-content {
  background: transparent;
}
.SVfhDHkj .ant-input-group-wrapper {
  background: var(--main-bg-color);
}
.SVfhDHkj .ant-divider-horizontal {
  margin: 16px 0;
}
.SVfhDHkj .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border: none;
  box-shadow: none;
}
.SVfhDHkj .ant-form-item-explain.ant-form-item-explain-error {
  margin-top: -21px;
  margin-bottom: 5px;
}
.Jby7xT9U {
  background: var(--module-bg);
  padding: 16px 24px 24px;
  margin-top: 16px;
  grid-column-start: 1;
  grid-column-end: 3;
}
.Jby7xT9U .ant-table-thead > tr > th {
  padding: 6px;
  height: 57px;
  white-space: nowrap;
}
.Jby7xT9U .ant-table-tbody > tr > td {
  padding: 10px 6px;
  height: 57px;
}
.Jby7xT9U .ant-table-container table > thead > tr:first-child th:last-child.ant-table-cell-scrollbar {
  width: 0;
  padding: 0;
}
.Jby7xT9U .ant-table-cell {
  /* white-space:nowrap; 防止IE等浏览器不支持'max-content'属性 导致内容换行 */
}
.Jby7xT9U .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.Jby7xT9U .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.__99N7ZpQn {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin-bottom: 20px;
}
.PCyj62p_ {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  display: flex;
  align-items: center;
}
.PCyj62p_ .pH2SMw_A {
  width: 20%;
}
.PCyj62p_ .__2m6czwUf {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.PCyj62p_ .rkwr3f4C {
  text-align: right;
  flex: 1;
}
.kPG8yi4E {
  z-index: 1000;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 4px;
}
.kPG8yi4E .pH2SMw_A {
  width: 20%;
}
.kPG8yi4E .__2m6czwUf {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.kPG8yi4E .rkwr3f4C {
  flex: 1;
  text-align: right;
}
.kMv0_N_f {
  background: var(--orderbook-red-bg);
}
.QnVJBTOM {
  background: var(--orderbook-green-bg);
}
.QnVJBTOM,
.kMv0_N_f {
  height: 22px;
  margin-bottom: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}
.iEYkGSB9 {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--red-text-color);
  line-height: 30px;
  margin: 16px 0;
}
.d3JQuQ7k {
  color: var(--red-text-color);
}
.eDC7COyv {
  color: var(--green-text-color);
}
.vdzxP00n {
  color: var(--green-text-color) !important;
}
._LV50jaU {
  color: var(--red-text-color) !important;
}
.b8aJrl19 {
  color: var(--white) !important;
}
.ef8KTXdz {
  height: 32px;
  background: var(--main-bg-color);
  border-radius: 4px;
  margin-bottom: 8px;
}
.D4Whlfi_ {
  width: 50%;
  height: 24px;
  background: var(--main-color);
  border-radius: 4px;
}
.QY9cVWbL {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 32px;
}
.CQBQ_YFV {
  border-radius: 4px;
  border: 1px solid var(--main-bg-color);
  margin-right: 8px;
  color: var(--text-color);
  padding: 0 10px;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  .__5mCEE3Rz {
    width: 100% !important;
    margin-left: 0;
  }
  .__48Z9KFQa {
    grid-template-columns: 2fr 1fr 1fr !important;
  }
}
.__4zVYYa41 .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.__4zVYYa41 .ant-form-item-control-input-content {
  background: transparent;
}
.__4zVYYa41 .ant-modal-body {
  margin-bottom: 48px;
}
.__4zVYYa41 .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.__4zVYYa41 .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.__4zVYYa41 .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.__4zVYYa41 .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.__4zVYYa41 .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
}
.__4zVYYa41 .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.__4zVYYa41 .WNYsNym9 :not(.gZ8107fd).yh0cjIUe,
.__4zVYYa41 .WNYsNym9 :not(.QAycPKgi).Rtdo0uuF,
.__4zVYYa41 .WNYsNym9 :not(.gZ8107fd).yh0cjIUe:hover,
.__4zVYYa41 .WNYsNym9 :not(.QAycPKgi).Rtdo0uuF:hover {
  background: var(--module-bg);
}
.__1i0b45PG .ant-drawer-body {
  background-color: var(--module-bg) !important;
  overflow: hidden;
}
.__1i0b45PG .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: var(--CommonBold);
  color: var(--white);
}
.__1i0b45PG .ant-tabs-tab {
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  padding: 0;
}
.__1i0b45PG .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.__1i0b45PG .ant-tabs-nav-wrap {
  height: 32px;
}
.__1i0b45PG .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.__1i0b45PG .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1i0b45PG .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.__1i0b45PG .H83a1KTj {
  background-color: var(--main-bg-color);
}
.__1i0b45PG .__67mZYHdF {
  margin: 20px 0;
  font-weight: 700;
  font-size: 18px;
}
.__1i0b45PG .__67mZYHdF .tQWNkzXU {
  cursor: pointer;
  margin-right: 15px;
  color: var(--white-op1);
}
.__1i0b45PG .__67mZYHdF .ph2YpMUG {
  cursor: pointer;
  margin-right: 15px;
}
.__1i0b45PG .AL6XEKKT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px;
  padding: 0 24px;
  border-top: 1px solid var(--border-color4);
  border-bottom: 1px solid var(--border-color4);
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .I2OQeQI7 {
  width: 3px;
  height: 3px;
  background: var(--main-color);
  border-radius: 50%;
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .Z0gc1clc {
  margin: 0 4px;
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .aT3Ez5TM {
  cursor: pointer;
  color: var(--main-color);
}
.__1i0b45PG .__1HCDuvtC {
  margin-top: 20px;
}
.__1i0b45PG .__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.__1i0b45PG .__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1i0b45PG .__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.__1i0b45PG .__1HCDuvtC .ant-table-column-sorter-up,
.__1i0b45PG .__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .__1HCDuvtC .pH2SMw_A {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .__1HCDuvtC .A0WeJSto {
  background-color: var(--btn-green-bg);
  color: var(--white);
  font-family: var(--CommonMedium);
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1i0b45PG .__1HCDuvtC .p8vw3pLv {
  background-color: var(--btn-red-bg);
  font-family: var(--CommonMedium);
  color: var(--white);
  border-radius: 2px;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1i0b45PG .ubTkJvVY {
  margin-top: 20px;
}
.__1i0b45PG .ubTkJvVY .pOVvvjEg {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 7px;
  margin-bottom: 16px;
  width: 156px;
  height: 96px;
  border: 1px solid;
  -o-border-image: linear-gradient(135deg, var(--border-color1), var(--module-bg)) 1;
     border-image: linear-gradient(135deg, var(--border-color1), var(--module-bg)) 1;
  color: var(--white-op3);
  cursor: pointer;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu {
  white-space: nowrap;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu .__13SLjrbl {
  font-size: 16px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu .CtyYG8SI {
  font-size: 12px;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .bwuJMMqV {
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR {
  font-size: 12px;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR .A0WeJSto {
  color: var(--green-text-color);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR .p8vw3pLv {
  color: var(--red-text-color);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .N46tFKA7 {
  color: var(--main-color);
}
.__1i0b45PG .ubTkJvVY .__2dcouDno {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__1i0b45PG .ubTkJvVY .__2dcouDno .sB98l3W4 {
  margin-top: 25px;
  cursor: pointer;
  font-family: var(--CommonMedium);
  color: var(--main-color);
}
.__1HCDuvtC {
  margin-top: 20px;
}
.__1HCDuvtC table:hover,
.__1HCDuvtC tr:hover,
.__1HCDuvtC thead:hover {
  background: var(--module-bg) !important;
}
.__1HCDuvtC .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.__1HCDuvtC .ant-table-column-sorter-up,
.__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.__1HCDuvtC .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1HCDuvtC .pH2SMw_A {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1HCDuvtC .A0WeJSto {
  background-color: var(--btn-green-bg);
  color: var(--white);
  font-family: var(--CommonMedium);
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1HCDuvtC .p8vw3pLv {
  background-color: var(--btn-red-bg);
  font-family: var(--CommonMedium);
  color: var(--white);
  border-radius: 2px;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.Hpoi36cA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
}
.Pjznub4T .ant-drawer-body {
  background-color: var(--module-bg) !important;
  overflow: hidden;
}
.Pjznub4T .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: var(--CommonBold);
  color: var(--white);
}
.Pjznub4T .ant-tabs-tab {
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  padding: 0;
}
.Pjznub4T .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.Pjznub4T .ant-tabs-nav-wrap {
  height: 32px;
}
.Pjznub4T .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.Pjznub4T .ant-table-tbody > tr > td {
  border-bottom: none;
}
.Pjznub4T .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.Pjznub4T .H83a1KTj {
  background-color: var(--main-bg-color);
}
.Pjznub4T .__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.Pjznub4T .__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.Pjznub4T .__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.Pjznub4T .__1HCDuvtC .ant-table-column-sorter-up,
.Pjznub4T .__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.Pjznub4T .__1HCDuvtC .__2hRpC3IZ {
  margin-right: 8px;
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.Pjznub4T .__1HCDuvtC .A_1v1Eoa {
  margin-right: 8px;
  font-size: 16px;
  color: var(--main-color);
}
.Pjznub4T .__67mZYHdF {
  margin: 20px 0;
  font-weight: 700;
  font-size: 18px;
}
.Pjznub4T .__67mZYHdF .tQWNkzXU {
  cursor: pointer;
  margin-right: 15px;
  color: var(--white-op1);
}
.Pjznub4T .__67mZYHdF .ph2YpMUG {
  cursor: pointer;
  margin-right: 15px;
}
.Pjznub4T .AL6XEKKT {
  margin: 0 -24px;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color4);
}
.Pjznub4T .hdjLab5a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px;
  padding: 0 24px 20px;
  border-bottom: 1px solid var(--border-color4);
}
.Pjznub4T .hdjLab5a .R6oWT5_C {
  font-family: var(--CommonBold);
  font-size: 20px;
}
.Pjznub4T .hdjLab5a .__8CP1PwAN {
  font-size: 16px;
  font-family: var(--CommonMedium);
  color: var(--main-color);
  cursor: pointer;
}
.Pjznub4T .HsPYjtIN {
  overflow-y: hidden;
  text-align: center;
  height: 95%;
}
.Pjznub4T .HsPYjtIN .__3Wq1gNUB {
  height: 90%;
  overflow-y: scroll;
  padding-bottom: 30px;
  z-index: 9999;
}
.Pjznub4T .HsPYjtIN .__3Wq1gNUB .__50QGHQtf {
  margin-top: 30px;
  width: 200px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q {
  height: 80px;
  display: flex;
  background-color: var(--module-bg);
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-color4);
  font-size: 16px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .nKTyZj8E {
  cursor: pointer;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .nKTyZj8E .SwGcHkQz {
  font-family: var(--CommonBold);
  color: var(--white);
  margin-left: 10px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .WjU25GcV {
  cursor: pointer;
  font-family: var(--CommonMedium);
  color: var(--white-op3);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .WjU25GcV:hover {
  color: var(--white);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .bwrAdxlV {
  cursor: not-allowed;
  font-family: var(--CommonMedium);
  color: var(--white-op5);
}
.__7U9U7dZy {
  background: var(--main-bg-color);
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__7U9U7dZy td {
  padding: 16px;
}
.__7U9U7dZy .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.__7U9U7dZy .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.__7U9U7dZy .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.gEr__WOi {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

